export const signUp = async (data: {
    email: string;
    password: string;
}): Promise<void> => {
    try {
	console.log(process.env.REACT_APP_API_BASE_URL); // Log the environment variable
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            },
        );

        if (!response.ok) {
            throw new Error('Failed to sign up');
        }
    } catch (error) {
        throw new Error('Email already registered!');
    }
};

export const fetchProjectsData = async (token: any) => {
    console.log('REACT_APP_API_BASE_URL:', process.env.REACT_APP_API_BASE_URL);
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/project`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
};

export const fetchProjectData = async (token: any, id: string | undefined) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/project/${id}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
};

export const fetchProfileData = async (token: any) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/profile`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return await response.json();
    } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
    }
};

export const updateProfile = async (token: any, formData: any) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/profile`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            },
        );
        if (!response.ok) {
            throw new Error('Failed to update profile');
        }
        return true;
    } catch (error) {
        throw new Error(`Error updating profile: ${error}`);
    }
};

export const verifyAccount = async (token: any) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/verify`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        if (!response.ok) {
            throw new Error('Failed to verify account');
        }
        console.log('Account verified successfully');
        return true;
    } catch (error) {
        throw new Error(`Error verifying account: ${error}`);
    }
};

export const login = async (data: { email: string; password: string }) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            },
        );

        if (!response.ok) {
            throw new Error('Failed to log in');
        }

        const responseData = await response.json();
        const token = responseData.access_token;

        localStorage.setItem('token', token);
        return token;
    } catch (error) {
        throw new Error(`Error logging in: ${error}`);
    }
};

export const createProject = async (token: any, formData: any) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/project`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(formData),
            },
        );
        if (!response.ok) {
            throw new Error('Failed to create project');
        }
        return true;
    } catch (error) {
        throw new Error(`Error creating project: ${error}`);
    }
};
