// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noProjects {
    margin-top: 30px;
    text-align: center;
    height: 100vh;
}

.createProject {
    margin-top: 30px;
    float: right;
}

.projects {
    margin-top: 20px;
    height: 100vh;
}

.projectsHeader {
    align-items: stretch;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.row.projects {
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/css/Projects.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".noProjects {\n    margin-top: 30px;\n    text-align: center;\n    height: 100vh;\n}\n\n.createProject {\n    margin-top: 30px;\n    float: right;\n}\n\n.projects {\n    margin-top: 20px;\n    height: 100vh;\n}\n\n.projectsHeader {\n    align-items: stretch;\n    display: flex;\n    height: 100%;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.row.projects {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
