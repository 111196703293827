import Cookies from 'js-cookie';

export const removeExpiredToken = () => {
    const token = localStorage.getItem('token');
    if (isTokenExpired(token)) {
        localStorage.removeItem('token');
        Cookies.remove('isLoggedIn');
        window.location.reload();
    }
};

const isTokenExpired = (token: string | null) => {
    if (!token) return true;
    const [, payload] = token.split('.');
    const decodedPayload = JSON.parse(atob(payload));
    const expirationTime = decodedPayload.exp * 1000;
    const currentTime = Date.now();
    return currentTime >= expirationTime;
};
