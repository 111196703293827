import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import '../css/Profile.css';
import { Container } from 'react-bootstrap';
import Layout from './Layout';
import { removeExpiredToken } from '../utils/tokenUtils';
import { ProfileDataProp, initialProfileState } from '../interfaces';
import { FailurePopUp, SuccessPopUp } from './forms/PopUp';
import {
    fetchProfileData,
    updateProfile,
    verifyAccount,
} from '../services/api';

const ProfilePage: React.FC = () => {
    const [formData, setFormData] =
        useState<ProfileDataProp>(initialProfileState);
    const [isOpen, setIsOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const openPopup = () => {
        setIsOpen(true);

        setTimeout(() => {
            setIsOpen(false);
        }, 5500);
    };
    const closePopup = () => setIsOpen(false);

    useEffect(() => {
        removeExpiredToken();

        const token = getToken();
        if (token) {
            fetchData(token);
        }
    }, []);

    const getToken = () => {
        return localStorage.getItem('token');
    };

    const fetchData = async (token: any) => {
        try {
            const data = await fetchProfileData(token);
            setFormData(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e: any) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = async (e: any) => {
        const token = getToken();
        e.preventDefault();
        try {
            const success = await updateProfile(token, formData);
            if (success) {
                setSuccess(true);
            }
        } catch (error) {
            setSuccess(false);
            console.error(error);
        }
    };

    const handleVerifyAccount = async (e: any) => {
        const token = getToken();
        e.preventDefault();
        try {
            await verifyAccount(token);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Layout>
            {isOpen &&
                (success ? (
                    <SuccessPopUp
                        message="Profile saved successfully"
                        isOpen={isOpen}
                        onClose={closePopup}
                    />
                ) : (
                    <FailurePopUp
                        message="Profile information not saved"
                        isOpen={isOpen}
                        onClose={closePopup}
                    />
                ))}

            <Container fluid className="cont">
                <Form onSubmit={handleFormSubmit}>
                    <Form.Group controlId="firstname">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="firstname"
                            value={formData.firstname}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="lastname">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="lastname"
                            value={formData.lastname}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="personalId">
                        <Form.Label>Personal Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="personalId"
                            value={formData.personalId}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="address">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="zipcode">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                            type="text"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Button onClick={openPopup} variant="primary" type="submit">
                        Save
                    </Button>
                    <Button
                        onClick={handleVerifyAccount}
                        variant="primary"
                        type="submit"
                    >
                        Verify account
                    </Button>
                </Form>
            </Container>
        </Layout>
    );
};

export default ProfilePage;
