import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NotFound from './pages/NotExistPage';
import ErrorBoundary from './components/ErrorBoundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignUpPage from './pages/SignUpPage';
import PrivateRoutes from './components/PrivateRoutes';
import ProfilePage from './pages/ProfilePage';
import Cookies from 'js-cookie';
import ProjectsPage from './pages/ProjectsPage';
import ProjectPage from './pages/ProjectPage';
import { removeExpiredToken } from './utils/tokenUtils';

function App() {
    const apiUrl = process.env.REACT_APP_API_URL;

    console.log('API URL:', apiUrl);
    console.log('Environment:', process.env);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        const isLoggedInCookie = Cookies.get('isLoggedIn');
        if (isLoggedInCookie === 'true') {
            setIsLoggedIn(Boolean(isLoggedInCookie));
        }
        setIsLoading(false);

        if (removed) {
            removeExpiredToken();
            setRemoved(true);
        }
    }, [removed]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <ErrorBoundary>
            <Router>
                <div>
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                <LoginPage
                                    onLoginStatusChange={setIsLoggedIn}
                                />
                            }
                        />
                        <Route
                            element={<PrivateRoutes isLoggedIn={isLoggedIn} />}
                        >
                            <Route path="/" element={<HomePage />} />
                            <Route path="/home" element={<HomePage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route
                                path="/projects"
                                element={<ProjectsPage />}
                            />
                            <Route
                                path="/project/:id"
                                element={<ProjectPage />}
                            />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                        <Route path="/signup" element={<SignUpPage />} />
                    </Routes>
                </div>
            </Router>
        </ErrorBoundary>
    );
}

export default App;
