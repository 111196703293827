import React from 'react';
import '../../css/ProgressCard.css';
import { ProfileDataProp } from '../../interfaces';

interface ProgressCardProps {
    stepName: string;
    profileData: ProfileDataProp;
    navigateTo: () => void;
}

const ProgressCard: React.FC<ProgressCardProps> = ({
    profileData,
    stepName,
    navigateTo,
}) => {
    function handleProfileData() {
        if (profileData.verified === true) {
            return 'Verified';
        } else {
            return 'Not verified';
        }
    }

    return (
        <div className="progress-card">
            <h2>{stepName}</h2>
            <p>Status: {handleProfileData()}</p>
            <button onClick={() => navigateTo()}>Go to Page</button>
        </div>
    );
};

export default ProgressCard;
