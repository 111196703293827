// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin-right: 25px;
    width: 300px;
    margin-top: 40px;
    height: 300px;
    text-align: center;
}

.progress-card h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
}

.progress-card p {
    margin: 0;
    font-size: 14px;
}

.progress-card button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;
}

.progress-card button:hover {
    background-color: #0056b3;
}

.progress-cards-column {
    height: 100vh;
    display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/css/ProgressCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,aAAa;AACjB","sourcesContent":[".progress-card {\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    padding: 10px;\n    margin-right: 25px;\n    width: 300px;\n    margin-top: 40px;\n    height: 300px;\n    text-align: center;\n}\n\n.progress-card h3 {\n    margin-top: 0;\n    margin-bottom: 10px;\n    font-size: 16px;\n}\n\n.progress-card p {\n    margin: 0;\n    font-size: 14px;\n}\n\n.progress-card button {\n    background-color: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    padding: 6px 12px;\n    cursor: pointer;\n    font-size: 14px;\n}\n\n.progress-card button:hover {\n    background-color: #0056b3;\n}\n\n.progress-cards-column {\n    height: 100vh;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
