import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { removeExpiredToken } from '../utils/tokenUtils';
import { fetchProjectData } from '../services/api';
import { ProjectDataProp, initialProjectState } from '../interfaces';

const ProjectPage: React.FC = () => {
    const { id } = useParams();
    const [formData, setFormData] =
        useState<ProjectDataProp>(initialProjectState);

    const getToken = () => {
        return localStorage.getItem('token');
    };

    useEffect(() => {
        const token = getToken();
        if (token && id) {
            fetchData(token, id);
        }

        removeExpiredToken();
    }, [id]);

    const fetchData = async (token: any, id: string | undefined) => {
        try {
            const data = await fetchProjectData(token, id);
            setFormData(data[0]);
        } catch (error) {
            console.error(error);
        }
    };

    function handleFormSubmit(): void {
        throw new Error('Function not implemented.');
    }

    function handleInputChange(): void {
        throw new Error('Function not implemented.');
    }

    return (
        <Layout>
            <Container fluid className="cont">
                <Form onSubmit={handleFormSubmit}>
                    <Form.Group controlId="clientId">
                        <Form.Label>Client ID</Form.Label>
                        <Form.Control
                            type="text"
                            name="clientId"
                            value={formData.client_id}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                            type="text"
                            name="status"
                            value={formData.status}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="webUrl">
                        <Form.Label>Web URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="webUrl"
                            value={formData.webUrl}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="privacyUrl">
                        <Form.Label>Privacy URL</Form.Label>
                        <Form.Control
                            type="text"
                            name="privacuUrl"
                            value={formData.privacyUrl}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="createdAt">
                        <Form.Label>Created At</Form.Label>
                        <Form.Control
                            type="text"
                            name="createdAt"
                            value={formData.createdAt}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="updatedAt">
                        <Form.Label>Update At</Form.Label>
                        <Form.Control
                            type="text"
                            name="updatedAt"
                            value={formData.updatedAt}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                </Form>
            </Container>
        </Layout>
    );
};

export default ProjectPage;
