import React from 'react';
import '../../css/PopUp.css';

interface PopupProps {
    isOpen: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    message?: string;
}

export const CreateProjectPopUp: React.FC<PopupProps> = ({
    isOpen,
    onClose,
    children,
}) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-btn" onClick={onClose}>
                    X
                </button>
                {children}
            </div>
        </div>
    );
};

export const SuccessPopUp: React.FC<PopupProps> = ({
    message,
    isOpen,
    onClose,
}) => {
    if (!isOpen) return null;

    return (
        <div className="container-fluid">
            <div className="success-popup-overlay">
                <div className="success-popup-content">
                    <div className="row">
                        <div className="col-md-2">
                            <img
                                src="../checkmark.png"
                                alt="success"
                                width="40px"
                                height="40px"
                            />
                        </div>
                        <div className="col-md-8">
                            <h4>Success!</h4>
                            <p>{message}</p>
                        </div>
                        <div className="col-md-2">
                            <img
                                src="../close.png"
                                alt="close"
                                width="20px"
                                height="20px"
                                onClick={onClose}
                                className="close-btn-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const FailurePopUp: React.FC<PopupProps> = ({
    message,
    isOpen,
    onClose,
}) => {
    if (!isOpen) return null;

    return (
        <div className="container-fluid">
            <div className="failure-popup-overlay">
                <div className="failure-popup-content">
                    <div className="row">
                        <div className="col-md-2">
                            <img
                                src="../failure.png"
                                alt="success"
                                width="40px"
                                height="40px"
                            />
                        </div>
                        <div className="col-md-8">
                            <h4>Failure!</h4>
                            <p>{message}</p>
                        </div>
                        <div className="col-md-2">
                            <img
                                src="../close.png"
                                alt="close"
                                width="20px"
                                height="20px"
                                onClick={onClose}
                                className="close-btn-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
