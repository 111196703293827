import React, { ReactNode } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/Layout.css';
import Cookies from 'js-cookie';

type Props = {
    children: ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate();

    function logOut() {
        localStorage.removeItem('token');
        Cookies.remove('isLoggedIn');
        navigate('/login');
    }

    function handleClick() {
        logOut();
    }
    return (
        <div className="container-fluid">
            <div className="row d-flex flex-grow-1">
                <div className="col-lg-2 col-md-3 col-sm-4 navigation-column navigation d-flex flex-column">
                    <h1 id="logo">Kasaras.</h1>
                    <ul className="flex-grow-1">
                        <li>
                            <Link className="navigation" to="/">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/projects"
                                className="custom-link navigation"
                            >
                                Projects
                            </Link>
                        </li>
                    </ul>
                    <button onClick={handleClick} className="logOut">
                        Log Out
                    </button>
                </div>
                <div className="col-lg-10 main-content d-flex flex-column">
                    <div id="header">
                        <h1 className="header">Home</h1>
                        <Link to="/profile">
                            <img
                                src="../profile.png"
                                alt="icon"
                                width="35px"
                                height="35px"
                            />
                        </Link>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
