// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signUpPageContainer {
    display: grid;
    place-items: center;
    height: 100vh;
    background-color: aliceblue;
}

.sign-up-card {
    border: 1px solid rgb(185, 185, 185);
    border-radius: 5px;
    padding: 15px;
    box-shadow: 2px 2px 10px rgba(69, 69, 69, 0.5);
}`, "",{"version":3,"sources":["webpack://./src/css/SignUpPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,aAAa;IACb,8CAA8C;AAClD","sourcesContent":[".signUpPageContainer {\n    display: grid;\n    place-items: center;\n    height: 100vh;\n    background-color: aliceblue;\n}\n\n.sign-up-card {\n    border: 1px solid rgb(185, 185, 185);\n    border-radius: 5px;\n    padding: 15px;\n    box-shadow: 2px 2px 10px rgba(69, 69, 69, 0.5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
