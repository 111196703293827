import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { createProject } from '../../services/api';

interface CreateProjectFormProps {
    onSaveButtonClick: () => void;
    onSucess: (sucessState: boolean) => void;
}

const CreateProjectForm: React.FC<CreateProjectFormProps> = ({
    onSaveButtonClick,
    onSucess,
}) => {
    const [formData, setFormData] = useState({
        webUrl: '',
        privacyUrl: '',
    });

    const getToken = () => {
        return localStorage.getItem('token');
    };

    function handleInputChange(
        event: React.ChangeEvent<HTMLInputElement>,
    ): void {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    }

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        const token = getToken();
        e.preventDefault();
        try {
            const success = await createProject(token, formData);
            onSaveButtonClick();
            onSucess(success);
        } catch (error) {
            onSaveButtonClick();
            onSucess(false);
            console.error(error);
        }
    };

    return (
        <Container fluid className="cont">
            <Form onSubmit={handleFormSubmit}>
                <h5>Create new Project</h5>
                <Form.Group controlId="webUrl">
                    <Form.Label>Website Url</Form.Label>
                    <Form.Control
                        type="text"
                        name="webUrl"
                        value={formData.webUrl}
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Form.Group controlId="PrivacyUrl">
                    <Form.Label>Privacy Url</Form.Label>
                    <Form.Control
                        type="text"
                        name="privacyUrl"
                        value={formData.privacyUrl}
                        onChange={handleInputChange}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </Form>
        </Container>
    );
};

export default CreateProjectForm;
