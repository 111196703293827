import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
import ProgressCard from './forms/ProgressCard';
import { removeExpiredToken } from '../utils/tokenUtils';
import { ProfileDataProp, initialProfileState } from '../interfaces';
import { fetchProfileData } from '../services/api';
const HomePage: React.FC = () => {
    const navigate = useNavigate();
    const [formData, setFormData] =
        useState<ProfileDataProp>(initialProfileState);

    useEffect(() => {
        const token = getToken();
        removeExpiredToken();
        fetchData(token);
    }, []);

    function navigateTo(url: string) {
        navigate(url);
    }

    const getToken = () => {
        return localStorage.getItem('token');
    };

    const fetchData = async (token: any) => {
        try {
            const data = await fetchProfileData(token);
            setFormData(data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Layout>
            <div className="container">
                <div className="row stepcards">
                    <div className="progress-cards-column">
                        <ProgressCard
                            profileData={formData}
                            stepName="Account verification"
                            navigateTo={() => navigateTo('/profile')}
                        />
                        <ProgressCard
                            profileData={formData}
                            stepName="Projects"
                            navigateTo={() => navigateTo('/projects')}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default HomePage;
