import React from 'react';
import { useEffect, useState } from 'react';
import Layout from './Layout';
import Card from './forms/Card';
import '../css/Card.css';
import '../css/Projects.css';
import CreateProjectForm from './forms/CreateProject';
import { Button } from 'react-bootstrap';
import { removeExpiredToken } from '../utils/tokenUtils';
import { CreateProjectPopUp, FailurePopUp, SuccessPopUp } from './forms/PopUp';
import { fetchProjectsData } from '../services/api';

const ProjectsPage: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const openNotificationPopup = () => {
        setNotificationOpen(true);

        setTimeout(() => {
            setNotificationOpen(false);
        }, 5500);
    };

    const openPopup = () => setIsOpen(true);
    const closePopup = () => setIsOpen(false);

    function handleSaveButtonClick() {
        openNotificationPopup();
        setIsOpen(false);
    }

    function handleSuccess(successState: boolean) {
        setSuccess(successState);
    }

    useEffect(() => {
        const token = getToken();
        if (token) {
            fetchData(token);
        }

        removeExpiredToken();
    }, []);

    const getToken = () => {
        return localStorage.getItem('token');
    };

    const fetchData = async (token: any) => {
        try {
            const data = await fetchProjectsData(token);
            setData(data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Layout>
            {notificationOpen &&
                (success ? (
                    <SuccessPopUp
                        message="Profile saved successfully"
                        isOpen={notificationOpen}
                        onClose={closePopup}
                    />
                ) : (
                    <FailurePopUp
                        message="Profile information not saved"
                        isOpen={notificationOpen}
                        onClose={closePopup}
                    />
                ))}
            <div className="container">
                <div className="row">
                    <CreateProjectPopUp isOpen={isOpen} onClose={closePopup}>
                        <CreateProjectForm
                            onSaveButtonClick={handleSaveButtonClick}
                            onSucess={handleSuccess}
                        />
                    </CreateProjectPopUp>
                    {data.length === 0 ? (
                        <></>
                    ) : (
                        <div className="col-12 createProject d-flex justify-content-end">
                            <Button onClick={openPopup} variant="primary">
                                Create Project
                            </Button>
                        </div>
                    )}
                </div>
                <div className="row projects">
                    <div className="projectsHeader d-flex flex-wrap">
                        {data.map((project) => (
                            <div key={project.id} className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
                                <Card
                                    title={project.webUrl}
                                    webUrl={project.privacyUrl}
                                    projectId={project.id}
                                />
                            </div>
                        ))}
                        {data.length === 0 && (
                            <div className="col-12 text-center noProjects">
                                <h1>No projects found!</h1>
                                <h2>Do you want to add a project?</h2>
                                <Button variant="primary" onClick={openPopup}>
                                    Create Project
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>


        </Layout>

    );
};

export default ProjectsPage;
