export interface ProfileDataProp {
    firstname: string;
    lastname: string;
    email: string;
    personalId: string;
    address: string;
    zipcode: string;
    city: string;
    verified: boolean;
}

export const initialProfileState = {
    firstname: '',
    lastname: '',
    email: '',
    personalId: '',
    address: '',
    zipcode: '',
    city: '',
    verified: false,
};

export interface ProjectDataProp {
    id: number;
    client_id: string;
    status: string;
    webUrl: string;
    privacyUrl: string;
    createdAt: string;
    updatedAt: string;
}

export const initialProjectState = {
    id: 0,
    client_id: '',
    status: '',
    webUrl: '',
    privacyUrl: '',
    createdAt: '',
    updatedAt: '',
};
