import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/SignUpPage.css';
import { signUp } from '../services/api';

const SignUpPage: React.FC = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSignUpFormSubmit = async (data: {
        email: string;
        password: string;
    }): Promise<void> => {
        try {
            await signUp(data);
            navigate('/login');
        } catch (error) {
            setError('Email is already registered!');
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        let hasErrors = false;

        if (!email) {
            setEmailError('Email is required');
            hasErrors = true;
        } else if (!validateEmail(email)) {
            setEmailError('Email is not valid');
            hasErrors = true;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Password is required');
            hasErrors = true;
        } else if (password.length < 5) {
            setPasswordError('Password must be at least 6 characters');
            hasErrors = true;
        } else {
            setPasswordError('');
        }

        if (hasErrors) {
            return;
        }

        handleSignUpFormSubmit({ email, password });
    };

    return (
        <div className="signUpPageContainer">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="sign-up-card">
                            <div className="card-header">
                                <h3 className="card-title">Sign Up</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                        <div className='errorBox'>
                                            <div className={`text-danger ${emailError ? '' : 'hidden'}`}>
                                                {emailError}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Enter password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                        <div className='errorBox'>
                                            <div className={`text-danger ${passwordError ? '' : 'hidden'}`}>
                                                {passwordError}
                                            </div>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="text-danger mb-3">
                                            {error}
                                        </div>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary submitas"
                                    >
                                        Sign Up
                                    </button>
                                </form>
                                <p id="registerp">
                                    Already have an account?{' '}
                                    <a href="login">Login</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpPage;
