import React from 'react';
import { Link } from 'react-router-dom';

type CardProps = {
    title: string;
    webUrl: string;
    projectId: string;
};

const Card: React.FC<CardProps> = ({ title, webUrl, projectId }) => {
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{webUrl}</p>

                <Link to={`/project/${projectId}`}>
                    <button className="btn btn-primary">Details</button>
                </Link>
            </div>
        </div>
    );
};

export default Card;
