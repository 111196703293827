import React, { useState } from 'react';
import '../css/LoginPage.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { login } from '../services/api';

interface LoginPageProps {
    onLoginStatusChange: (isLoggedIn: boolean) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLoginStatusChange }) => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLoginFormSubmit = async (data: {
        email: string;
        password: string;
    }) => {
        try {
            await login(data);
            onLoginStatusChange(true);
            Cookies.set('isLoggedIn', 'true', { expires: 1 });
            navigate('/home');
        } catch (error) {
            setError('Invalid email or password');
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        let hasErrors = false;

        if (!email) {
            setEmailError('Email is required');
            hasErrors = true;
        } else if (!validateEmail(email)) {
            setEmailError('Email is not valid');
            hasErrors = true;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Password is required');
            hasErrors = true;
        } else if (password.length < 5) {
            setPasswordError('Password must be at least 6 characters');
            hasErrors = true;
        } else {
            setPasswordError('');
        }

        if (hasErrors) {
            return;
        }

        handleLoginFormSubmit({ email, password });
    };



    return (
        <div className='loginPageContainer'>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="login-card">
                            <div className="card-header">
                                <h3 className="card-title">Login</h3>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                        <div className='errorBox'>
                                            <div className={`text-danger ${emailError ? '' : 'hidden'}`}>
                                                {emailError}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            placeholder="Enter password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                        />
                                        <div className='errorBox'>
                                            <div className={`text-danger ${passwordError ? '' : 'hidden'}`}>
                                                {passwordError}
                                            </div>
                                        </div>
                                    </div>
                                    {error && (
                                        <div className="text-danger mb-3">
                                            {error}
                                        </div>
                                    )}
                                    <button
                                        type="submit"
                                        className="btn btn-primary submitas"
                                    >
                                        Login
                                    </button>
                                </form>
                                <p id="registerp">
                                    Don't have account?{' '}
                                    <a href="signup">Register</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
