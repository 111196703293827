import React from 'react';

const NotFound = () => {
    return (
        <div>
            <h2>404 Not Found</h2>
            <p>Oops! Page not found.</p>
        </div>
    );
};

export default NotFound;
